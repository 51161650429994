import React, { useState, useEffect } from 'react';
import ConfirmModal from './ConfirmModal';
import Pagination from '../utils/Pagination';
import { Form, Divider, Popover, Button, Flex, Space, Checkbox, Card, Modal } from 'antd';
const CheckboxGroup = Checkbox.Group;

function Request(props) {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [filterData, setFilterData] = useState({ status: [], offset: 0 });
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [confirmModalData, setConfirmModalModalData] = useState({ id: 0, name: '', status: 0 });
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  function getData(api, filter = filterData) {
    api.getRequests(filter)
      .then(res => {
        setPagination({ totalPages: res.totalPages, currentPage: res.currentPage });
        setData(res.data);
      })
      .catch(err => {
        console.log(err);
      })
  }

  function handlePageChange(page) {
    setFilterData({ ...filterData, offset: 5 * (page - 1) });
    getData(props.Api, { ...filterData, offset: 5 * (page - 1) });
  }

  function formatStatus(status) {
    if (status == 1) {
      return <span className='card__item card__status' style={{ backgroundColor: '#0000FF' }}>Новая</span>
    } else if (status == 2) {
      return <span className='card__item card__status' style={{ backgroundColor: '#CD853F' }}>Доставка подтверждена</span>
    } else if (status == 3) {
      return <span className='card__item card__status' style={{ backgroundColor: '#50C878' }}>Шиномонтаж подтвержден</span>
    } else if (status == 4) {
      return <span className='card__item card__status' style={{ backgroundColor: '#d9534f' }}>Отменено</span>
    }
  }

  function handleStatusButtons(id, status) {
    setConfirmModalIsOpen(true);

    let name = '';
    if (status == 1)
      name = 'Подтвердить доставку?';
    else if (status == 2)
      name = 'Подтвердить шиномонтаж?';
    else if (status == 3)
      name = 'Отменить заявку?';
    else
      name = 'Отменить заявку?';

    setConfirmModalModalData({ id: id, status: status + 1, name: name });
  }

  const filterDataHandler = (values) => {
    let filter = [];

    // Если выбран чекбокс "Все", сбрасываем фильтр
    if (values.selectAll) {
      setFilterData({ ...filterData, status: [] });
    } else if (values.checkboxGroup && values.checkboxGroup.length > 0) {
      // Добавляем статусы в зависимости от выбранных чекбоксов
      if (values.checkboxGroup.includes('new')) filter.push('1');
      if (values.checkboxGroup.includes('delivery')) filter.push('2');
      if (values.checkboxGroup.includes('shin')) filter.push('3');
      if (values.checkboxGroup.includes('cancel')) filter.push('4');
    }

    // Обновляем состояние фильтра и запрашиваем данные
    setFilterData({ ...filterData, status: filter });
    getData(props.Api, { ...filterData, status: filter });
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  useEffect(() => {
    if (props.Api)
      getData(props.Api);
  }, [props.Api]);

  const plainOptions = [
    { label: 'Новая', value: 'new' },
    { label: 'Доставка подтверждена', value: 'delivery' },
    { label: 'Шиномонтаж подтвержден', value: 'shin' },
    { label: 'Отменено', value: 'cancel' }
  ];

  const [checkedList, setCheckedList] = useState([]);
  const [checkAll, setCheckAll] = useState(plainOptions.length === checkedList.length);

  const onChange = (list) => {
    setCheckedList(list);
  };

  const onCheckAllChange = (e) => {
    if (e.target.checked) {
      // Если выбрано "Все", устанавливаем пустой массив для сброса
      setCheckedList([]);
      form.setFieldsValue({ checkboxGroup: [] });
    } else {
      setCheckedList([]);
      form.setFieldsValue({ checkboxGroup: [] });
    }
    setCheckAll(e.target.checked);
  };

  function handleCloseButton() {
    setConfirmModalIsOpen(false);
    setConfirmModalModalData({ id: 0, status: 0, name: '' });
  }

  function handleSubmit(e) {
    props.Api.updateRequestStatus(confirmModalData.status, confirmModalData.id)
      .then(res => {
        if (res) {
          window.location.reload();
        }
      })

    handleCloseButton();
  }

  return (
    <div className='content'>
      <div className='request'>
        <fieldset className='request__buttons'>
          <Button size='large' type='primary' onClick={() => getData(props.Api)}>Обновить список</Button>
          <Divider type='vertical' />
          <Popover className='request__filter' trigger='click' placement='bottomRight' title={'Фильтр'} content={
            <Form
              form={form}
              name="filter-panel"
              onFinish={filterDataHandler}
              autoComplete="off">
              <Space direction='vertical' size='small'>

                <Form.Item name="selectAll" valuePropName="checked">
                  <Checkbox indeterminate={checkedList.length > 0 && checkedList.length < plainOptions.length} onChange={onCheckAllChange}
                    defaultChecked={true} checked={checkAll}>Все</Checkbox>
                </Form.Item>

                <Divider style={{ marginBottom: 4, marginTop: -16 }} />
                <Form.Item name="checkboxGroup">
                  <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} style={{ flexDirection: 'column' }} />
                </Form.Item>

                <Button size='middle' type='primary' htmlType='submit'>Применить</Button>
              </Space>
            </Form>
          }
            open={open}
            onOpenChange={handleOpenChange}>
          </Popover>
        </fieldset>

        <ul className='request__list'>
          {data.map((item, index) => {
            return <Card className='card' key={index} title={
              <div className='card__header'>
                <span className='card__item card__number'>№{item.id}</span>
                {formatStatus(item.status)}
                <span className='card__item card__date'>{item.date}</span>
              </div>
            }>

              <div className='card__content'>
                <p><strong>Дата доставки:&nbsp;</strong>{item.reqDate}</p>
                <p><strong>Продавец:&nbsp;</strong>{item.reqSeller}</p>
                <p><strong>Шины:&nbsp;</strong>{item.reqDesc}</p>
                <p><strong>Клиент:&nbsp;</strong>{item.reqName}</p>
                <p><strong>Телефон:&nbsp;</strong> <a href={`tel:${item.reqPhone}`}>{item.reqPhone}</a></p>
              </div>

              <div className='card__actions'>
                <button disabled={item.status > 1} onClick={() => handleStatusButtons(item.id, item.status)}>Подтвердить доставку</button>
                <button disabled={item.status <= 1} onClick={() => { document.location.href = `tel:${item.reqPhone}` }}>Позвонить клиенту</button>
                <button disabled={!(item.status == 2)} onClick={() => handleStatusButtons(item.id, item.status)}>Подтвердить шиномонтаж</button>
                <button className='card__actions_cancel' disabled={item.status >= 2} onClick={() => handleStatusButtons(item.id, 3)}>Отменить заявку</button>
              </div>
            </Card>
          })}
        </ul>

        <Pagination totalPages={pagination.totalPages} currentPage={pagination.currentPage} onPageChange={handlePageChange}></Pagination>
      </div>

      <Modal title={confirmModalData.name} open={confirmModalIsOpen} onOk={handleSubmit}
        onCancel={() => setConfirmModalIsOpen(false)} centered >

      </Modal>

    </div >
  );
};

export default Request;