import React, { useContext, useEffect, useState } from 'react';
import { CurrentUserContext } from '../contexts/CurrentUserContext';
import useFormWithValidation from '../utils/Validator';
import { useRole } from '../contexts/RoleContext';
import { Form, Checkbox, Flex, Input, Space, Button, Card, notification } from 'antd';

function Company(props) {
  const { role } = useRole();
  const [form] = Form.useForm();
  const [loads, setLoads] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [values, setValues] = useState({});

  const openNotification = (mess) => {
    api.open({
      message: 'Обновление организации',
      description: mess,
      duration: 3,
    });
  };

  function handleSubmit(e) {
    e.preventDefault();

    props.Api.updateProfile(values)
      .then(() => {
        window.location.reload();
      })
      .catch(err => {
        console.log(err)
      });
  };

  const onFinish = (values) => {
    setLoads(true);

    console.log(values);

    setTimeout(() => {
      props.Api.updateCompany(values)
        .then(() => {
          openNotification('Профиль организации успешно обновлен');
        })
        .catch((err) => {
          console.log(err);
          openNotification('Ошибка обновления организации');
        })
        .finally(() => {
          setLoads(false);
        });
    }, 1000);
  };

  useEffect(() => {
    if (props.Api)
      props.Api.getCompany().then(res => {
        form.setFieldsValue(res);
      });
  }, [props.Api]);

  if (role !== 'moder')
    return null;

  return (
    // <div className='profile' onSubmit={handleSubmit} noValidate>

    <Flex noValidate style={{ maxWidth: 1000, margin: 20 }}>
      {contextHolder}
      <Card title='Заполнить информацию организации' style={{ maxWidth: 1000 }}>
        <Form
          form={form}
          onFinish={onFinish}
          layout='vertical'
          style={{ maxWidth: 1000 }}
        >
          <Space direction='vertical' size='large'>
            {/*Первая строка*/}
            <Flex gap='large' wrap>
              <Form.Item
                name='name'
                label='Наименование организации'
                layout='vertical'
                style={{ minWidth: 230 }}
                rules={[
                  {
                    required: true,
                    message: 'Поле имеет ошибочный формат',
                    min: 2,
                    max: 50,
                  },
                ]}
              >
                <Input placeholder='Введите наименование' />
              </Form.Item>
              <Form.Item
                name='address'
                label='Юридический адрес'
                layout='vertical'
                style={{ minWidth: 230 }}
                rules={[
                  {
                    required: true,
                    message: 'Поле имеет ошибочный формат',
                    pattern: '^[А-Яа-яЁё]+',
                    min: 2,
                    max: 50,
                  },
                ]}
              >
                <Input placeholder='Юридический адрес' />
              </Form.Item>
              <Form.Item
                name='schedule'
                label='График работы'
                layout='vertical'
                style={{ minWidth: 230 }}
                rules={[
                  {
                    message: 'Поле имеет ошибочный формат',
                    min: 2,
                    max: 250,
                  },
                ]}
              >
                <Input placeholder='9:00-18:00 Пн-Пт' />
              </Form.Item>
            </Flex>
            {/*Вторая строка*/}
            <Flex gap='large' wrap>
              <Form.Item
                name='phone'
                label='Контактный номер'
                layout='vertical'
                style={{ minWidth: 230 }}
                rules={[
                  {
                    required: true,
                    message: 'Поле имеет ошибочный формат',
                    min: 2,
                    max: 16,
                  },
                ]}
              >
                <Input type='phone' placeholder='+7(812)123-45-67' />
              </Form.Item>

              <Form.Item
                name='email'
                label='Контактный email'
                layout='vertical'
                style={{ minWidth: 230 }}
                rules={[
                  {
                    message: 'Поле имеет ошибочный формат',
                    min: 2,
                    max: 256,
                  },
                ]}
              >
                <Input type='email' placeholder='email@email.ru' />
              </Form.Item>

              <Form.Item
                name='coords'
                label='Координаты яндекс'
                layout='vertical'
                style={{ minWidth: 230 }}
                rules={[
                  {
                    required: true,
                    message: 'Поле имеет ошибочный формат',
                    min: 2,
                    max: 256,
                  },
                ]}
              >
                <Input type='text' placeholder='59.943988, 30.306329' />
              </Form.Item>
            </Flex>

            <Button size='middle' type='primary'
              htmlType='submit' loading={loads}>
              Сохранить
            </Button>
          </Space>
          <Form.Item
            name='id'
            style={{ display: "none" }}
          ></Form.Item>
        </Form>
      </Card>
    </Flex >
  );
};

export default Company;
