import React, { useContext, useEffect, useState } from 'react';
import { CurrentUserContext } from '../contexts/CurrentUserContext';
import { Form, Checkbox, Flex, Input, Space, Button, Card, notification } from 'antd';

function Profile(props) {
  const user = useContext(CurrentUserContext).profile;
  const [form] = Form.useForm();
  const [values, setValues] = useState({});
  const [loads, setLoads] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [isFormChanged, setIsFormChanged] = useState(false);

  const openNotification = (mess) => {
    api.open({
      message: 'Обновление профиля',
      description: mess,
      duration: 3,
    });
  };

  const onFinish = (values) => {
    setLoads(true);
    setTimeout(() => {
      props.Api.updateProfile(values)
        .then(() => {
          openNotification('Профиль успешно обновлен');
        })
        .catch((err) => {
          console.log(err);
          openNotification('Ошибка обновления профиля');
        })
        .finally(() => {
          setLoads(false);
        });
    }, 1000);
  };

  useEffect(() => {
    setValues(user);
    form.setFieldsValue(user);
  }, [props.Api, user]);

  let confirmMessage = `Я${', ' + values.surname} ${values.name} ${values.patronymic} подтверждаю своё согласие на передачу информации в электронной форме (в том числе персональных данных) по открытым каналам связи сети Интернет, а также на обработку персональных данных.`;

  return (
    <Flex noValidate style={{ maxWidth: 1000, margin: 20 }}>
      {contextHolder}
      <Card title='Заполнить информацию профиля' style={{ maxWidth: 1000 }}>
        <Form
          form={form}
          onFinish={onFinish}
          layout='vertical'
          style={{ maxWidth: 1000 }}
          onFieldsChange={() => {
            setIsFormChanged(form.isFieldsTouched(true));
          }}
        >
          <Space direction='vertical' size='large'>
            {/*Первая строка*/}
            <Flex gap='large' wrap>
              <Form.Item
                name='surname'
                label='Фамилия'
                layout='vertical'
                style={{ minWidth: 230 }}
                rules={[
                  {
                    required: true,
                    message: 'Поле имеет ошибочный формат',
                    pattern: '^[А-Яа-яё]+$',
                    min: 2,
                    max: 50,
                  },
                ]}
              >
                <Input
                  placeholder='Введите фамилию'
                  onChange={(e) => setValues({ ...values, surname: e.target.value })}
                />
              </Form.Item>
              <Form.Item
                name='name'
                label='Имя'
                layout='vertical'
                style={{ minWidth: 230 }}
                rules={[
                  {
                    required: true,
                    message: 'Поле имеет ошибочный формат',
                    pattern: '^[А-Яа-яЁё]+',
                    min: 2,
                    max: 50,
                  },
                ]}
              >
                <Input
                  placeholder='Введите имя'
                  onChange={(e) => setValues({ ...values, name: e.target.value })}
                />
              </Form.Item>
              <Form.Item
                name='patronymic'
                label='Отчество (если есть)'
                layout='vertical'
                style={{ minWidth: 230 }}
                rules={[
                  {
                    message: 'Поле имеет ошибочный формат',
                    pattern: '^[А-Яа-яЁё]+',
                    min: 2,
                    max: 50,
                  },
                ]}
              >
                <Input
                  placeholder='Введите отчество'
                  onChange={(e) => setValues({ ...values, patronymic: e.target.value })}
                />
              </Form.Item>
            </Flex>
            {/*Вторая строка*/}
            <Flex gap='large' wrap>
              <Form.Item
                name='phone'
                label='Контактный номер'
                layout='vertical'
                style={{ minWidth: 230 }}
                rules={[
                  {
                    required: true,
                    message: 'Поле имеет ошибочный формат',
                    min: 2,
                    max: 16,
                  },
                ]}
              >
                <Input type='phone' placeholder='+7(812)123-45-67' />
              </Form.Item>

              <Form.Item
                name='email_contact'
                label='Контактный email'
                layout='vertical'
                style={{ minWidth: 230 }}
                rules={[
                  {
                    message: 'Поле имеет ошибочный формат',
                  },
                ]}
              >
                <Input type='email' placeholder='email@email.ru' />
              </Form.Item>
            </Flex>
          </Space>

          <Space style={{ marginTop: 50, maxWidth: 1280 }}>
            <Form.Item name='accept' rules={[{ required: true }]}>
              <Checkbox checked={values.accept}
                onChange={() => setValues({ ...values, accept: !values.accept })}>{confirmMessage}</Checkbox>
            </Form.Item>
            <Button size='middle' type='primary'
              htmlType='submit' loading={loads} disabled={!isFormChanged}>
              Сохранить
            </Button>
          </Space>
        </Form>
      </Card>
    </Flex >
  );
}

export default Profile;
